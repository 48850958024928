import { Component, Mixins } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { formatDate } from '@/utils/functions'
// Types
import { ProgramMonthLargeResource } from '@/store/types'

@Component
export default class CourseProgramMonthMixin extends Mixins(NotifyMixin) {
  protected get courseID () {
    return +this.$route.params.courseID
  }

  protected get monthID () {
    return +this.$route.params.monthID
  }

  protected get breadcrumbsLesson () {
    return this.month ? [
      { name: 'Курсы', path: '/manager/control/courses' },
      { name: this.courseTitle, path: `/manager/control/courses/${this.month.course.id}/${this.month.course.groupTypes[0].value}/program` },
      { name: 'Уроки', path: `/manager/control/courses/${this.month.course.id}/program/${this.month.id}/lessons` },
    ] : []
  }

  protected get breadcrumbsExercise () {
    return this.month ? [
      { name: 'Курсы', path: '/manager/control/courses' },
      { name: this.courseTitle, path: `/manager/control/courses/${this.month.course.id}/${this.month.course.groupTypes[0].value}/program` },
      { name: 'Домашняя работа', path: `/manager/control/courses/${this.month.course.id}/program/${this.month.id}/exercises` },
    ] : []
  }

  protected get month (): ProgramMonthLargeResource | null {
    return ManagerCoursesModule.programMonth
  }

  protected get monthName () {
    return this.month ? formatDate(this.month.date, 'LLLL') : ''
  }

  protected get courseTitle () {
    return this.month ? this.month.course.title : ''
  }

  private created () {
    this.fetchMonth()

    // Добавили задание из банка ДЗ
    this.$bus.$on('add-exercise-from-template', this.fetchMonth)

    if (this.$route.name === 'manager.control.courses.item.program.month') {
      this.$router.replace({
        name: 'manager.control.courses.item.program.month.lessons',
        params: {
          courseID: this.courseID.toString(),
          monthID: this.monthID.toString(),
        },
      })
    }
  }

  protected fetchMonth () {
    ManagerCoursesModule.fetchProgramMonth({
      courseID: this.courseID,
      monthID: this.monthID,
    })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.control.courses' })
      })
  }
}
